import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Graphic Design",
      desc: "I am adept at transforming your visions into visually captivating designs. Leveraging industry-leading techniques and a creative instinct, I can assist in crafting standout graphic content that speaks your brand's language. Elevate your visual narrative with my expert design support.",
      icon: "fas fa-palette",
    },
    {
      name: "Web Development",
      desc: "As a skilled web developer, I offer expert solutions tailored to your digital needs. Harnessing the power of the latest coding practices and web technologies, I build dynamic, responsive, and user-friendly websites that boost your online presence. Let's collaborate to shape your digital footprint with cutting-edge web development.",
      icon: "fas fa-desktop",
    },
    {
      name: "UI/UX Design",
      desc: "In the realm of UI/UX Design, I provide compelling and user-centric solutions that enhance digital experiences. My designs aim to streamline navigation, improve user interaction, and promote your brand's identity, making each user's journey seamless and engaging. Let's partner to craft impactful and intuitive design interfaces that resonate with your audience.",
      icon: "fas fa-pencil-ruler",
    },
    // {
    //   name: "App Design & Develop",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    //   icon: "fas fa-paint-brush",
    // },
    // {
    //   name: "Business Analysis",
    //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    //   icon: "fas fa-chart-area",
    // },
    {
      name: "SEO Marketing",
      desc: "I offer SEO Marketing strategies that improve your website's visibility. Harnessing the power of targeted keywords and data analysis, I create content that rises in search rankings. Let's work together to ensure your online presence doesn't just exist, but thrives.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "E-Commerce Solutions",
      desc: "I provide E-Commerce Solutions, creating secure, user-friendly online storefronts. From product management to secure payment gateways, I transform your business into a digital retail powerhouse. Let's optimize your platform for success in the bustling E-Commerce landscape.",
      icon: "fas fa-shopping-cart",
    },
    {
      name: "Sales Channels",
      desc: "Unlock the power of social media with my comprehensive solutions for sales channels like Facebook and Instagram. I can help integrate these platforms into your sales strategy to create a seamless shopping experience. Harness the potential of millions of daily users and convert social engagement into tangible business growth.",
      icon: "fas fa-shopping-bag",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
