import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Jordan",
      position: "Rec. Director Ace Gymnastics",
      src: "images/projects/acetestimonial.png",
      desc: "“The website Joe developed is visually stunning and highly functional, providing our users with a seamless and enjoyable experience. He clearly has a deep understanding of the latest web development technologies and trends, and leveraged that knowledge to create a truly exceptional product.”",
    },
    {
      name: "Justin",
      position: "Owner at Moore Quality Finishes",
      src: "images/projects/moorequalitytestimonial.png",
      desc: "“They did an amazing job creating a website for our company! Going back and forth with me making sure we were going to be happy with the final product. I highly recommend them!”",
    },
    {
      name: "Angela",
      position: "Owner at Davangela Candles",
      src: "images/projects/davangelatestimonial.png",
      desc: "“Overall, I was very impressed with his expertise and dedication to helping me achieve my goals. If you're looking for a skilled and reliable web developer to optimize your website for mobile devices, I would definitely recommend working with him.”",
    },
    {
      name: "Debi",
      position: "Owner at Cactus Annie's",
      src: "images/projects/cactusannietestimonial.png",
      desc: "“We can’t say enough about Joe and his company! He did an amazing job for us! Always willing to go the extra mile. Highly Recommend!!!”",
    },
    {
      name: "David",
      position: "Twitch Streamer",
      src: "images/projects/dronerace32webtestimonial.png",
      desc: "“I can’t give Bourbon City Web Development enough credit for this logo. I was literally blown away when I received it. I changed nothing about it. If anyone is in the market for anything like this Joe is my go to guy.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Client Speak</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What Some of my Clients Say
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
