import React from "react";
import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import { scrollDuration } from "../../../config/commonConfig";
import introImage from "../../../../src/documents/introimage.png";
import "./fullScreen.css";

const FullScreenDefaultIntro = () => {
  return (
    <section
      id="home"
      className="bg-primary d-flex fullscreen position-relative py-5"
    >
      <div className="container my-auto py-4">
        <div className="row">
          <div className="col-lg-7 text-center text-lg-start align-self-center order-1 order-lg-0 wow fadeIn">
            <h1
              className="text-12 fw-300 mb-0 text-uppercase"
              style={{ color: "white" }}
            >
              Bourbon City Web Development
            </h1>
            <h2
              style={{ color: "#1B1B1B" }}
              className="text-21 fw-600 text-uppercase mb-0 ms-n1"
            >
              <Typewriter
                options={{
                  strings: [
                    "Engineer",
                    "Developer",
                    "Designer",
                    "Coder",
                    "Artist",
                    "Creator",
                    "Maker",
                    "Innovator",
                    "Dreamer",
                    "Thinker",
                    "Doer",
                    "Learner",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h2>
            <p style={{ color: "white" }} className="text-5">
              based in Bardstown, KY
            </p>
            <Link
              className="btn btn-dark rounded-0 smooth-scroll mt-3"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="portfolio"
            >
              View My Works
            </Link>
            <Link
              className="btn btn-link smooth-scroll mt-3"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{
                cursor: "pointer",
                color: "white",
                textDecoration: "none",
              }}
              to="contact"
            >
              Contact Me
              <span className="text-4 ms-2">
                <i className="far fa-arrow-alt-circle-down" />
              </span>
            </Link>
          </div>
          <div className="col-lg-5 cartoon-img-container">
            <img src={introImage} alt="Illustration of Joe the Engineer" />
          </div>
        </div>
      </div>
      <Link
        className="scroll-down-arrow text-dark smooth-scroll"
        smooth="easeInOutQuint"
        duration={scrollDuration}
        style={{ cursor: "pointer" }}
        to="about"
      >
        <span className="animated">
          <i className="fas fa-arrow-down" />
        </span>
      </Link>
    </section>
  );
};

export default FullScreenDefaultIntro;
