import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    ECOMM: "E-Commerce",
    UIUX: "UI/UX",
    SALES: "Sales Channels",
    WEB: "Web Development",
    GRAPHIC: "Graphic Design",
    SEO: "SEO",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Ace Gymnastics",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Ace Gymnastics is a cherished family-owned gymnastics facility, situated in the heart of Bardstown, KY. Our primary objective for this project was to establish a robust online presence and enhance the convenience of class registration and event booking for parents. To achieve this, we integrated Jackrabbit, a highly acclaimed management system that simplifies class registration processes for parents, enabling them to register and pay online seamlessly. Additionally, we incorporated a user-friendly calendar that displays all upcoming classes and events. We have also ensured that our website is mobile-friendly, allowing parents to register for classes on-the-go, anytime and anywhere.",
        client: "Ace Gymnastics",
        technologies:
          "React, HTML5, CSS3, JavaScript, Material-UI, Contentful, Node, Heroku",
        industry: "Web Development",
        date: "2022",
        url: {
          name: "www.ace-gymnastics.com",
          link: "https://www.ace-gymnastics.com",
        },

        sliderImages: [
          "images/projects/ace-gymnastics1.png",
          "images/projects/ace-gymnastics2.png",
          "images/projects/ace-gymnastics3.png",
        ],
      },

      thumbImage: "images/projects/acelogo.jpeg",

      categories: [filters.WEB, filters.SEO, filters.UIUX],
    },
    {
      title: "Moore Quality Finishes",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I had the privilege of collaborating with Moore Quality Finishes, a distinguished painting company, on creating a custom website that perfectly reflects their standard of excellence. This user-centric platform highlights the exceptional services they offer, from interior painting to exterior finishes. One of the website's unique features is a dedicated section that showcases the team's accomplished projects, allowing visitors to appreciate the superior quality of their work firsthand. In addition, a 'Meet the Team' page was incorporated, giving a human touch to the brand and allowing potential clients to familiarize themselves with the professionals behind the craft. Beyond the website development, I assisted Moore Quality Finishes in establishing and optimizing their Google Business Account, effectively broadening their digital footprint and enhancing their visibility on search engine results. This comprehensive project not only boosted their online presence but also provided a streamlined platform for customers to explore and engage with their wide array of services.",
        client: "Moore Quality Finishes",
        technologies:
          "React, HTML5, CSS3, JavaScript, Material-UI, Contentful, Node, Heroku",
        industry: "Web Development",
        date: "2023",
        url: {
          name: "www.moorequalityfinishes.com",
          link: "https://www.moorequalityfinishes.com",
        },

        sliderImages: [
          "images/projects/moorequality1.png",
          "images/projects/moorequality2.png",
          "images/projects/moorequality3.png",
          "images/projects/moorequality4.png",
        ],
      },

      thumbImage: "images/projects/MooreQualityFinishesLogo.png",

      categories: [filters.WEB, filters.SEO, filters.UIUX],
    },
    {
      title: "Cactus Annie's",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Nestled in the heart of Bardstown, KY, Cactus Annie's is a distinctive boutique that sought to marry the charm of its physical store with the reach of the online marketplace. The goal was to create a seamless e-commerce platform that could integrate effortlessly with their existing Square inventory system. Even though working with Square was new terrain for me, I embraced the challenge and found it an enriching experience to learn and adapt to a new technology. Despite Square's inherent limitations, I crafted a versatile website that delivers an exceptional visual experience, irrespective of whether accessed via mobile or desktop. The development process was highly collaborative, with Debbie, the boutique owner, working closely with me to ensure her brand's unique personality and charm were perfectly encapsulated in the design. But the project scope extended beyond just website creation. I also successfully integrated Facebook and Instagram Sales Channels into the platform, effectively expanding Cactus Annie's digital footprint. Now, customers can enjoy the uniqueness of Cactus Annie's offerings from the comfort of their homes, while the boutique taps into a wider market, further solidifying its presence in the retail space.",
        client: "Cactus Annie's",
        technologies:
          "Square, Facebook, Instagram, Pixel, Javascript, CSS, Photoshop, Canva",
        industry: "Web Development",
        date: "2023",
        url: {
          name: "www.cactusannies.com",
          link: "https://www.cactusannies.com",
        },

        sliderImages: [
          "images/projects/cactusannies1.png",
          "images/projects/cactusannies2.png",
          "images/projects/cactusannies3.png",
        ],
      },

      thumbImage: "images/projects/cactusannieslogo.png",

      categories: [
        filters.WEB,
        filters.SEO,
        filters.UIUX,
        filters.ECOMM,
        filters.SALES,
        filters.GRAPHIC,
      ],
    },
    {
      title: "DroneRacer Twitch Stream",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I had an absolute joy immersing myself in the creative process of designing a distinctive logo for David Lawson Twitch and YouTube gaming channels. This design is not just a reflection of David's likeness, but it is an embodiment of his unique interests, especially his fascination with the extraterrestrial. The result was not just a logo, but a graphic echo of David's personal brand in the gaming world.",
        client: "David Lawson",
        technologies: "Photoshop, Illustrator, Canva, Adobe Creative Cloud",
        industry: "Graphic Design",
        date: "2023",
        url: {
          name: "https://www.twitch.tv/droneracer32",
          link: "https://www.twitch.tv/droneracer32",
        },

        sliderImages: ["images/projects/dronerace32web.png"],
      },

      thumbImage: "images/projects/dronerace32web.png",

      categories: [filters.GRAPHIC],
    },
    {
      title: "Social Avator",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Delving into the world of personalized illustration was a delightful endeavor as I crafted a striking social media profile picture for a cherished client. The final design was not just a simple avatar but a creative blend of the client's likeness and unique personality, a testament to our in-depth consultation and collaborative work. ",
        client: "Tim Lawsom",
        technologies: "Photoshop, Illustrator, Canva, Adobe Creative Cloud",
        industry: "Graphic Design",
        date: "2023",
        url: {
          name: "unavailable",
          link: "unavailable",
        },

        sliderImages: ["images/projects/timremake.png"],
      },

      thumbImage: "images/projects/timremake.png",

      categories: [filters.GRAPHIC],
    },
    {
      title: "Contractor Company Website",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "In response to Nate Wallace's request from Redmont Builders, a prestigious construction company based in West Michigan, I embarked on the task of creating a comprehensive, user-friendly, and visually appealing website. Utilizing Squarespace's platform, we fashioned a site that captured the essence of Redmont Builders, their offered services, and encapsulated their unique brand identity. The project's focal point was maintaining simplicity without compromising on aesthetics or functionality. Key features include an intuitive contact form designed to facilitate seamless communication from clients, enabling them to send project information directly to Redmont Builders' email. This mechanism streamlines customer engagement and enhances the overall user experience. Additionally, comprehensive information about the company and its diverse range of services was incorporated, presenting a full spectrum view of what Redmont Builders brings to the table. By leveraging the affordability and user-friendliness of Squarespace, we delivered a high-quality website at a significantly lower cost than what a custom-built site would entail. The end result is a digital platform that accurately represents Redmont Builders and serves as an effective tool for customer interaction. The project was met with enthusiasm from Nate, who expressed his sheer delight with the final product, reflecting the success of our collaborative effort.",
        client: "Redmont Builders",
        technologies:
          "Squarespace, Photoshop, Canva, Adobe Creative Cloud, HTML, CSS, Javascript",
        industry: "Web Development",
        date: "2023",
        url: {
          name: "www.redmontbuilders.com",
          link: "https://www.redmontbuilders.com",
        },

        sliderImages: [
          "images/projects/redmontbuilder1.jpg",
          "images/projects/redmontbuilder2.jpg",
          "images/projects/redmontbuilder3.jpg",
          "images/projects/redmontbuilder4.jpg",
        ],
      },

      thumbImage: "images/clients/redmontbuilderlogo.png",

      categories: [filters.WEB, filters.SEO, filters.UIUX],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">
                              {project.document.industry}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
