import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "How do you price your services?",
      answer: `I use a fixed-price model for my services. This model is based on the specific requirements and complexity of each individual project.<br><br> To determine the cost, I first have an in-depth conversation with the client to fully understand the scope of the project. I then estimate the amount of time and resources needed to complete the project, and provide a single, all-inclusive cost.

        This pricing model offers benefits to my clients as it provides certainty about the total cost from the outset, without any hidden fees or additional charges. The price quoted at the start of the project will be the final price you pay, regardless of the time I spend on the project.
        
        <br><br>While my pricing takes into consideration industry standards, the complexity of the tasks, and the value I bring to my clients, I am always open to discussing your budget to find a solution that works for both of us. Transparency is very important to me, so I ensure all aspects of pricing are communicated clearly and openly to my clients.`,
    },
    {
      question: "How long will it typically take to complete my project?",
      answer: `The timeline for project completion depends greatly on the specific requirements and complexity of the project. To provide an accurate estimate, I would first need to understand the scope and requirements of the project in detail.

        <br><br>However, to give you a rough idea, a simple project, such as a basic website that has simple functionality, usually takes about 3 to 4 weeks. More complex projects, such as developing a large-scale e-commerce website, could take anywhere from 2 to 6 months. Please note these are general estimates and the actual timeline may vary.
        
        <br><br>Once I have a clear understanding of your project, I will be able to provide a more precise timeline. This will include various stages of the project like design, development, testing, and any revisions that may be needed.
        
        Throughout the project, I will keep you updated on the progress and any changes to the timeline that may arise due to unforeseen circumstances or changes in scope. My goal is to ensure that you are completely satisfied with the final product, and that it's delivered in a timely manner.`,
    },
    {
      question: "Why are your prices lower than the agencies?",
      answer: `My pricing is lower than a typical agency for a few reasons. <br><br>First, as a part-time freelancer with a full-time job as a software engineer, I have a steady income source, which allows me to offer competitive rates for my freelance work.

        <br><br>Secondly, as an individual freelancer, my overhead costs are much lower than those of an agency. Agencies typically have multiple employees, office space, and various other expenses that they need to cover. As an individual, I don't have these costs, which enables me to pass on these savings to my clients.
        
        <br><br>Lastly, while I offer high-quality work similar to an agency, the nature of my schedule may lead to longer project timelines. I prioritize thoroughness and quality over speed, and this is reflected in my lower pricing`,
    },
    {
      question:
        "How do you stay updated with the latest software development trends?",
      answer: `To stay updated with the latest software development trends, I leverage a mix of strategies. <br><br>I routinely partake in online courses, webinars, and read up on industry news from sources like TechCrunch and Hacker News. I'm also an active member of several professional online communities where I network and exchange knowledge with fellow professionals. In my projects, I aim to incorporate new technologies and practices whenever suitable, which gives me first-hand experience with the latest trends. I also regularly contribute to open source projects and read updated documentation from various software tools and platforms. <br><br>These collective efforts ensure I am able to consistently provide my clients with modern and efficient solutions.`,
    },
    {
      question: "What separates you from other freelancers?",
      answer: `What truly separates me from others is the unique blend of my passion for technology, commitment to client satisfaction, and the personal touch I bring to all my projects. <br><br>With every assignment, I take the time to fully understand the client's vision and needs, and I see each project as a chance to solve a unique problem, not just write code.

        In addition to my technical skills, my full-time job as a software engineer allows me to stay at the forefront of the industry's best practices, which I then apply to my freelance projects. <br><br>My part-time freelancing approach also enables me to offer competitive rates, providing a high-value proposition to my clients.
        
        I firmly believe that the strongest solutions are built on a foundation of clear communication and trust. To me, the relationship with my client is as important as the work I deliver. The joy I derive from coding is paralleled only by the satisfaction I receive when a client is genuinely happy with my work. <br><br>For me, being a software engineer is more than a job—it’s a calling. This passion drives me to go the extra mile and is what, I believe, truly separates me from the rest.`,
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have any questions?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
